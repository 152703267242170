import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useRouter } from 'next/router'

import { LazyLoad } from 'components/lazy-load'

import { logError } from 'lib/utils'
import { ComponentModifiersObject, DynamicComponent } from 'lib/@Types'

import { ApiComponent } from './api-component'

import s from './styles.module.scss'

interface ComponentMeta {
  componentId: string
  componentName: string
  componentType: string
  componentRank: number
  componentMetaType: 'api' | 'data'
  componentConfig?: any
  componentContent?: any
  componentEventId?: string
  componentModifiers?: ComponentModifiersObject
}

interface DynamicComponentsProps {
  components: DynamicComponent[]
  pageName: string
  destinationId?: string
  countryId?: string
  mapping: Record<string, React.ComponentProps<any> | JSX.Element>
  className?: string
  trackEvent?: TrackEventType
  formatEventAttribute?: FormatEventAttributeType
}

const DynamicComponents = ({
  components,
  pageName,
  destinationId,
  countryId,
  trackEvent,
  formatEventAttribute,
  mapping = {},
  className,
}: DynamicComponentsProps) => {
  const pageVariables = pageName === 'country' ? { countryId } : { destinationId }
  const sortedComponents = [...components].sort(
    (a: ComponentMeta, b: ComponentMeta) => a.componentRank - b.componentRank
  )

  const { asPath } = useRouter()
  // To make sure API component modifier is resets on page change
  const apiComponentKey = `${asPath.split('?')[0]}_${pageName}`

  const renderComponent =
    (Component: any, props: any = {}) =>
    // eslint-disable-next-line react/display-name
    (additionalProps: any = {}) => {
      const allProps = { ...props, ...additionalProps }

      if (React.isValidElement(Component)) {
        return React.cloneElement(Component, allProps)
      }

      return <Component {...allProps} />
    }

  const ErrorFallbackComponent = () => null

  return (
    <div className={s.dynamicComponents}>
      {sortedComponents.map(
        ({
          componentId,
          componentType,
          componentMetaType,
          componentContent,
          componentConfig,
          componentModifiers,
          componentEventId,
          componentRank,
        }: ComponentMeta) => {
          const Component = mapping[componentType]

          if (!Component || !componentContent) return null

          const RootComponent = Component.props?.lazyLoad ? LazyLoad : React.Fragment

          const commonRenderProps = {
            componentEventId,
            className,
            componentId,
            componentMetaType,
            componentType,
            componentRank,
            pageName,
            ...pageVariables,
            trackEvent,
            formatEventAttribute,
          }

          if (componentMetaType === 'data') {
            return (
              <RootComponent key={componentId}>
                <ErrorBoundary FallbackComponent={ErrorFallbackComponent} onError={logError}>
                  {renderComponent(Component, { ...commonRenderProps, componentContent })()}
                </ErrorBoundary>
              </RootComponent>
            )
          }

          if (!componentConfig) return null

          if (componentMetaType === 'api') {
            return (
              <RootComponent key={componentId}>
                <ErrorBoundary FallbackComponent={ErrorFallbackComponent} onError={logError}>
                  <ApiComponent
                    key={apiComponentKey}
                    componentConfig={componentConfig}
                    componentContent={componentContent}
                    componentModifiers={componentModifiers}
                  >
                    {renderComponent(Component, commonRenderProps)}
                  </ApiComponent>
                </ErrorBoundary>
              </RootComponent>
            )
          }

          return null
        }
      )}
    </div>
  )
}

export { DynamicComponents }

import React from 'react'
import cn from 'classnames'

import { Button } from 'ui/button'

import { Container } from 'components/container'
import { ExternalLink } from 'components/external-link'
import { DynamicComponentProps } from 'components/dynamic-components/types'

import useAuth from 'lib/hooks/useAuth'
import useTranslation from 'lib/hooks/useTranslation'
import { useClipboard } from 'lib/hooks/useClipboard'

import { useGlobalContext } from 'lib/context/global-context'

import { EVENTS } from 'lib/constants/events'

import PromoAbstract from 'brand-assets/illustrations/promo-abstract.svg'

import s from './styles.module.scss'

interface RewardsBannerProps extends DynamicComponentProps {
  trackEvent: TrackEventType
  className?: string
  variant?: 'default' | 'container' | 'left-align' | 'mini'
}

const RewardsBanner = ({
  componentContent,
  className,
  variant = 'default',
  trackEvent,
}: RewardsBannerProps) => {
  const { t } = useTranslation()
  const { isMobileView } = useGlobalContext()
  const { Clipboard } = useClipboard()
  const { link, header, button } = componentContent
  const { authModal } = useAuth()

  const onSignupNowClick = () => {
    trackEvent({ attributeId: EVENTS.SIGNUP_NOW_BY_PROMO, attributeType: EVENTS.ATTRIBUTES_TYPE.BUTTON })
    authModal.open({ screen: 'signup' })
  }

  const promo = (
    <div
      className={cn(
        s.box,
        cn({ [s.leftAlign]: variant === 'left-align' }),
        variant === 'default' || variant === 'left-align'
          ? className
          : variant === 'mini'
          ? s['mini-variant']
          : ''
      )}
    >
      <PromoAbstract className={s.background} />
      <Clipboard copiedText={t('exclm.codeCopied')}>
        <div className={s.body}>
          <div className={s.text}>
            <p>
              {header}
              {link && !isMobileView && (
                <span className={s.link}>
                  <ExternalLink href={link.url} className={s.link}>
                    {link.label}
                  </ExternalLink>
                </span>
              )}
            </p>
          </div>
          <div className={s.footer}>
            {button && (
              <Button
                className={s.promoCodeButton}
                onClick={onSignupNowClick}
                variant="primary-neon"
                size={isMobileView || variant === 'mini' ? 'small' : 'large'}
              >
                <div>{button?.label}</div>
              </Button>
            )}
            {link && isMobileView && (
              <span className={s.link}>
                <ExternalLink href={link.url}>{link.label}</ExternalLink>
              </span>
            )}
          </div>
        </div>
      </Clipboard>
    </div>
  )

  if (variant === 'container') {
    return <Container className={cn(className, s.container)}>{promo}</Container>
  }

  return promo
}

export { RewardsBanner }

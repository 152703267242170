import { Trans } from 'react-i18next'
import cn from 'classnames'

import { Button } from 'ui/button'

import { Container } from 'components/container'
import { DynamicComponentProps } from 'components/dynamic-components/types'
import { ExternalLink } from 'components/external-link'
import { ExposureTracker } from 'components/exposure-tracker'
import { HowItWorks } from 'components/how-it-works'

import useTranslation from 'lib/hooks/useTranslation'

import { useGlobalContext } from 'lib/context/global-context'
import { useAppData } from 'lib/context/app-data-context'

import { KRISFLYER_LINK } from 'lib/constants'
import { EVENTS } from 'lib/constants/events'

import RocketLaunchImage from 'brand-assets/illustrations/rocket-launch.svg'
import CircleWavyCheckImage from 'brand-assets/illustrations/circle-wavy-check.svg'
import GiftsImage from 'brand-assets/illustrations/gifts.svg'
import CurrencyDollarImage from 'brand-assets/illustrations/currency-circle-dollar.svg'
import PelagoBySIABlackLogo from 'brand-assets/illustrations/logo/pelago-by-sg-airline-desktop-black.svg'
import BookWithPelagoImage from 'brand-assets/illustrations/book-with-pelago.svg'

import s from './styles.module.scss'

const WhyBookWithPelago = ({
  trackEvent,
  componentEventId,
}: DynamicComponentProps & { trackEvent: TrackEventType }) => {
  const { t, tp, sp } = useTranslation(['booking', 'common'])
  const { isTabletView, isMobileView } = useGlobalContext()
  const {
    partnerFeatureControl: { showFeature },
  } = useAppData()

  const showKfOnVpBanner = showFeature('kfOnVpBanner')

  const props = [
    {
      icon: CurrencyDollarImage,
      title: t('action.bestPriceGuaranteed', { ns: 'common' }),
      description: (
        <>
          {tp('msg.cheaperElsewhereLetUsKnowWillRefundDifference', { ns: 'booking' })}
          {sp}
          <span className={s.kfLink}>
            <HowItWorks variant="value-props" />
          </span>
        </>
      ),
    },
    {
      // Hard dependency of the second prop to be hidden on webview. Update index if more props are added above this
      icon: GiftsImage,
      title: t('t.earnKrisFlyerRewards', { ns: 'common' }),
      description: (
        <>
          {tp('t.earnMilesForBookingAndUseMilesToRedeem', { ns: 'common' })}
          {sp}
          <ExternalLink className={s.kfLink} href={KRISFLYER_LINK}>
            <Button variant="link-tertiary" size="small">
              {t('action.learnMore', { ns: 'common' })}
            </Button>
          </ExternalLink>
        </>
      ),
    },
    {
      icon: CircleWavyCheckImage,
      title: t('msg.serviceThatSoars', { ns: 'common' }),
      description: (
        <>
          <Trans
            i18nKey="t.verifiedReviewsSecurePaymentsHighStandards"
            ns="common"
            components={{ bold: <strong /> }}
          />
          {t('lang.punctuation', { ns: 'common' })}
        </>
      ),
    },
    {
      icon: RocketLaunchImage,
      title: t('msg.connectToCulture', { ns: 'common' }),
      description: tp('msg.discoverTopRatedActivitiesHiddenGemsAndEverything', {
        ns: 'common',
      }),
    },
  ]
  return (
    <div className={s.box}>
      <Container>
        <div className={s.container}>
          {(isTabletView || isMobileView) && (
            <>
              <h3 className="col-7 header-3 text-grey-1">{t('q.whyBookWithPelago', { ns: 'common' })}</h3>
              <div className="col-5">
                <BookWithPelagoImage className={s.illustration} />
              </div>
            </>
          )}
          {!isTabletView && !isMobileView && (
            <>
              <h3 className="col-12 header-3 text-grey-1">{t('q.whyBookWithPelago', { ns: 'common' })}</h3>
              <BookWithPelagoImage className={s.illustration} />
            </>
          )}
          <div className={cn(s.inline, { [s['three-col']]: !showKfOnVpBanner })}>
            {props.map((prop, index) => {
              if (index === 1 && !showKfOnVpBanner) return null

              return (
                <div className={s.prop} key={index}>
                  <prop.icon />
                  <div>
                    <h6 className="header-6 text-grey-1 mb-1">{prop.title}</h6>
                    <div className="label-sm text-grey-1">{prop.description}</div>
                  </div>
                </div>
              )
            })}
          </div>
          <PelagoBySIABlackLogo className={s.logo} />
        </div>
      </Container>
      <ExposureTracker
        onExposure={() => {
          trackEvent({
            attributeId: componentEventId,
            attributeType: EVENTS.ATTRIBUTES_TYPE.SECTION,
            eventType: EVENTS.TYPE.EXPOSURE,
          })
        }}
      />
    </div>
  )
}

export { WhyBookWithPelago }

import React, { useMemo, useState } from 'react'
import cn from 'classnames'

import { Button } from 'ui/button'

import { ExposureTracker } from 'components/exposure-tracker'

import s from './styles.module.scss'

type ItemProps<T, K extends React.FunctionComponent<T>> =
  | Omit<React.ComponentProps<K>, 'indexInList'>
  | Record<string, any>

export interface GridProps<T = any> {
  columns: number
  className?: string
  loading?: boolean
  items: { key: string; [key: string]: any }[]
  itemsUnderDataKey?: boolean
  itemComponent: React.FunctionComponent<T>
  itemProps: ItemProps<T, GridProps<T>['itemComponent']>
  onClick?: (item: any, index: number) => void
  onExposure?: (items: any, index?: number) => void
  exposureOffset?: string
  exposureDependency?: any[]
  collapseSize?: number
  ctaText?: string
  onCtaClick?: () => void
}

export function Grid<T>({
  columns = 4,
  className,
  items,
  itemsUnderDataKey,
  itemComponent: Component,
  itemProps,
  onClick,
  onExposure,
  exposureOffset,
  exposureDependency,
  collapseSize,
  ctaText,
  onCtaClick,
}: GridProps<T>) {
  const [expand, setExpand] = useState(false)

  const columnClassName = `column-${columns}`

  const isLoading = items.every((item) => item.loading)

  const [visibleItems, hiddenItems] = useMemo(() => {
    if (collapseSize && !expand) {
      return [items.slice(0, collapseSize), items.slice(collapseSize, items.length)]
    }
    return [items, null]
  }, [items, collapseSize, expand])

  const renderItems = (item: any, index: number) => {
    return (
      <li key={item.key || index}>
        <Component
          indexInList={index}
          {...(itemsUnderDataKey ? { data: item } : item)}
          {...itemProps}
          onClick={() => onClick?.(item, index)}
        >
          {index % columns === 0 && !isLoading && (
            <ExposureTracker
              onExposure={() => {
                onExposure?.(items.slice(index, index + columns), index)
              }}
              bottomOffset={exposureOffset}
              deps={exposureDependency}
            />
          )}
        </Component>
      </li>
    )
  }

  return (
    <>
      <ul className={cn(s.row, s[columnClassName], className)}>{visibleItems.map(renderItems)}</ul>
      {!!hiddenItems && (
        <ul className={cn(s.row, s[columnClassName], s['hidden-for-seo'], className)}>
          {hiddenItems.map(renderItems)}
        </ul>
      )}
      {!!collapseSize && items.length > collapseSize && !expand && (
        <div className={s.cta}>
          <Button
            variant="trasparent"
            iconName="caret-down"
            iconPosition="right"
            size="large"
            onClick={() => {
              setExpand(true)
              onCtaClick?.()
            }}
          >
            {ctaText}
          </Button>
        </div>
      )}
    </>
  )
}

import React from 'react'
import cn from 'classnames'

import { Button } from 'ui/button'
import { Sizes } from 'ui/button/types'

import useTranslation from 'lib/hooks/useTranslation'

import s from './styles.module.scss'

interface ReadMoreCtaProps {
  isExpanded: boolean
  onClick?: () => void
  size?: Sizes
  ctaText?: string
  normalWeight?: boolean
}

const ReadMoreCta = ({ isExpanded, ctaText, normalWeight, onClick, size }: ReadMoreCtaProps) => {
  const { t } = useTranslation()

  return (
    <Button
      variant="link-tertiary"
      className={cn(s.expandCta, { [s.expanded]: isExpanded, [s.normalWeight]: normalWeight })}
      onClick={onClick}
      size={size}
    >
      {ctaText
        ? ctaText
        : isExpanded
        ? t('action.readLess', { ns: 'common' })
        : t('action.readMore', { ns: 'common' })}
    </Button>
  )
}

export { ReadMoreCta }

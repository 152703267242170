import React, { useMemo, useReducer } from 'react'
import { useQuery } from '@apollo/client'
import { isFunction } from 'lodash-es'

import { useAppData } from 'lib/context/app-data-context'
import { ApolloClientVersion, ComponentModifiersObject, Query } from 'lib/@Types'

import { APOLLO_CLIENT_VERSION } from 'lib/constants'

import { getQuery } from 'gql/mapper'

import { isSkipComponentRender, updatePlaceholders } from './utils'
import { ApiComponentModifierManager, ClientMapping } from './types'

interface ApiConfig {
  graphqlOperation: string
  graphqlParams?: any
}

interface ApiComponentDataType {
  config: ApiConfig
  dependencies?: string[]
  [key: string]: any
}

interface ApiComponentProps {
  componentContent: any
  componentModifiers?: ComponentModifiersObject
  componentConfig: ApiComponentDataType
  children?: React.ReactNode
}

const reducer = (state: ComponentModifiersObject, payload: any) => {
  if (payload.current && payload.modifierId) {
    return {
      ...state,
      [payload.modifierId]: {
        ...state[payload.modifierId],
        current: payload.current,
      },
    }
  }
  return state
}

// making it initialise function so that we can manipulte the initial state if needed
const initialise = (modifiers?: ComponentModifiersObject) => {
  return {
    ...(modifiers || {}),
  }
}

const getQueryOptionsData = (query: Query, config: Record<string, any>) => {
  // TODO: Remove this check once BE finishes migrating all component to new query format
  const endpointVersion = config.graphqlEndpointVersion as ApolloClientVersion
  if (query.queryName === 'searchFilterProducts') {
    if (endpointVersion === APOLLO_CLIENT_VERSION.SEARCH) {
      return {
        variables: config.graphqlParams,
        context: { version: endpointVersion },
      }
    }
    return {
      variables: { searchInput: { ...config.graphqlParams } },
      context: { version: APOLLO_CLIENT_VERSION.SEARCH },
    }
  }

  return {
    variables: config.graphqlParams,
    ...(endpointVersion && {
      context: { version: endpointVersion },
    }),
  }
}

const ApiComponent = ({
  componentContent,
  componentModifiers,
  componentConfig,
  children,
}: ApiComponentProps) => {
  const { activityLog } = useAppData()

  const [modifiers, updateModifier] = useReducer(reducer, componentModifiers, initialise)

  const clientMapping = useMemo<ClientMapping>(
    () => ({
      'recent-pids': activityLog.products.map((product) => product.productId),
    }),
    [activityLog.products]
  )

  // udpate config and content placeholders when modifiers/clientMapping changes
  const [content, config, skipComponentRender] = useMemo(() => {
    const _config = updatePlaceholders(componentConfig.config, { modifiers, clientMapping })
    const _content = updatePlaceholders(componentContent, { modifiers, clientMapping })
    const _skipComponentRender = isSkipComponentRender(componentConfig?.dependencies, { config: _config })

    return [_content, _config, _skipComponentRender]
  }, [clientMapping, componentConfig, componentContent, modifiers])

  const query = getQuery(config.graphqlOperation)

  const queryOptionsData = getQueryOptionsData(query, config)
  const { data, error, loading } = useQuery(query.query, {
    ...queryOptionsData,
    skip: !query || skipComponentRender,
  })

  const hasResponseError = data?.[query.queryName]?.code >= 400

  const modifierManager = useMemo<ApiComponentModifierManager>(
    () => ({
      // @ts-ignore
      list: Object.keys(modifiers || {})?.map?.((modifierId) => ({
        modifierId,
        ...modifiers[modifierId],
      })),
      update: updateModifier,
    }),
    [modifiers]
  )

  if (!query || error || hasResponseError || !isFunction(children) || skipComponentRender) return null

  return children({
    loading,
    modifierManager,
    componentContent: { ...content },
    componentQueryData: data?.[query.queryName] || data,
  })
}

export { ApiComponent }

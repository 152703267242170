import React from 'react'
import cn from 'classnames'

import { ProductDetailsLink } from 'components/product-details-link'
import { Rating } from 'components/rating'

import { ReadMoreCta } from 'page-modules/product/read-more-cta'

import ApostropheImg from 'brand-assets/illustrations/apostrophe.svg'

import s from './styles.module.scss'

type ReviewCardProps = {
  title?: string
  rating?: number
  displayComment: string
  showReadMoreCta: boolean
  firstName?: string
  travellerType?: string
  productId?: string
  productUri?: string
  productName?: string
  onExpandClick?: () => void
  onProductNameClick?: (data: { id: string }) => void
}

const ReviewCard = ({
  title,
  rating,
  displayComment,
  showReadMoreCta,
  firstName,
  travellerType,
  productId,
  productUri,
  productName,
  onExpandClick,
  onProductNameClick,
}: ReviewCardProps) => {
  return (
    <div className={cn(s.reviewCard)}>
      <div className={s.background}>
        <div className={s.body}>
          <Rating value={rating || 0} isReadOnly />
          <p className={cn(s.comment, s.ellipsize)}>
            {title && (
              <>
                {title}
                <span className={s.separator} />
              </>
            )}
            {displayComment}
          </p>
          {showReadMoreCta && <ReadMoreCta isExpanded={false} onClick={onExpandClick} size="small" />}
        </div>
        <div className={s.footer}>
          <div className={s.nameAndType}>
            <span>{firstName}</span>
            {travellerType && (
              <>
                <span className={s.separator}>.</span>
                <span>{travellerType}</span>
              </>
            )}
          </div>
          <div className={s.flex}>
            <div className={s.dateOrProductName}>
              <ProductDetailsLink
                productId={productId}
                productUri={productUri}
                onClick={() => {
                  onProductNameClick?.({ id: productId || '' })
                }}
              >
                {productName}
              </ProductDetailsLink>
            </div>
          </div>
        </div>
        <div className={s.apostrophe}>
          <ApostropheImg className="mr-1" />
          <ApostropheImg />
        </div>
      </div>
    </div>
  )
}

export { ReviewCard }

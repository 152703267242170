import React from 'react'
import cn from 'classnames'

import { noop } from 'lib/utils'

import StarIcon from 'brand-assets/illustrations/icons/rating-star.svg'

import SmallStar from './small-star.svg'

import s from './styles.module.scss'

const STAR_COUNT = 5

interface RatingProps {
  size?: 'sm' | 'md' | 'lg'
  value: number
  onChange?: (value: number) => void
  onHover?: (value: number) => void
  isReadOnly?: boolean
  isCompact?: boolean
  labelValue?: React.ReactNode
  labelClassName?: string
}

const Rating = ({
  size,
  value: valueProp,
  onChange = noop,
  onHover = noop,
  isReadOnly = false,
  labelValue,
  isCompact = false,
  labelClassName,
}: RatingProps) => {
  const value = Math.max(Math.min(valueProp, STAR_COUNT), 0) // constraint the value between 0 n STAR_COUNT

  if (isReadOnly) {
    const ratingStyle: any = { '--value': value }

    return (
      <fieldset className={cn(s.ratingContainer)} title={value.toString()}>
        {labelValue && <span className={cn(s.ratingValue, 'bold', labelClassName)}>{labelValue}</span>}
        <div
          className={cn(s.rating, s.isReadOnly, s[size || ''], { [s.isCompact]: isCompact })}
          style={ratingStyle}
        />
      </fieldset>
    )
  }

  const inputId = (id: number) => `input-${id}`

  return (
    <fieldset className={cn(s.rating, s.selectable, s[size || ''])}>
      {Array(STAR_COUNT)
        .fill(0)
        .map((_, index) => {
          const starValue = STAR_COUNT - index

          return (
            <span
              className={s.star}
              key={index} /* eslint-disable-line react/no-array-index-key */
              onMouseEnter={() => onHover(starValue)}
              onMouseLeave={() => onHover(0)}
            >
              <input
                id={inputId(index)}
                name="rating"
                type="checkbox"
                className="visually-hidden"
                value={starValue}
                checked={starValue <= value}
                onChange={() => onChange(starValue)}
              />
              <label className={s.starLabel} htmlFor={inputId(index)}>
                <StarIcon />
                {index === 0 && (
                  <>
                    <SmallStar data-star="1" />
                    <SmallStar data-star="2" />
                    <SmallStar data-star="3" />
                    <SmallStar data-star="4" />
                    <SmallStar data-star="5" />
                  </>
                )}
              </label>
            </span>
          )
        })}
    </fieldset>
  )
}

export { Rating }

import { useCallback, useMemo } from 'react'

import { Chip } from 'ui/chip'

import { Container } from 'components/container'
import { ExposureTracker } from 'components/exposure-tracker'

import { DynamicComponent } from 'lib/@Types'
import { sentenceCase } from 'lib/utils'

import { EVENTS } from 'lib/constants/events'

import { IconNames } from 'brand-assets/icon-lib'
import FireIcon from 'brand-assets/illustrations/fire.svg'

import s from './styles.module.scss'

type Item = {
  id: string
  label: string
  url: string
  iconName: string
}
const ExpandableChips = ({
  items,
  componentEventId,
  truncateLongText,
  trackEvent,
}: {
  items: Item[]
  componentEventId?: string
  truncateLongText?: boolean
  trackEvent?: TrackEventType
}) => {
  const onChipsClick = useCallback(
    (id) => {
      trackEvent?.({
        attributeId: componentEventId,
        attributeType: EVENTS.ATTRIBUTES_TYPE.CHIPS,
        attributeValue: {
          id,
        },
      })
    },
    [componentEventId, trackEvent]
  )

  const visibleChips = useMemo(() => {
    return items?.map(({ label, url, iconName, id }: Item, index: number) => (
      <Chip
        key={url}
        id={id}
        label={label}
        size="small"
        variant="light"
        data-index={index}
        iconName={iconName as IconNames}
        asLink={`/${url}`}
        onClick={onChipsClick}
        truncateLongText={truncateLongText}
      />
    ))
  }, [items, onChipsClick, truncateLongText])

  return <div className={s.chipsContainer}>{visibleChips}</div>
}
const WhatIsPopular = ({
  componentContent,
  componentEventId,
  componentId,
  trackEvent,
}: DynamicComponent & { trackEvent?: TrackEventType }) => {
  const { header, sections } = componentContent

  return (
    <Container>
      <div className={s.popularContainer}>
        <h3 className={s.title}>
          <FireIcon className={s.fireIcon} />
          {sentenceCase(header)}
        </h3>

        <div className={s.body}>
          {sections.map(({ header, items }: any) =>
            items?.length ? (
              <div key={header} className={s.chipHeader}>
                <h5>{header}</h5>
                <ExpandableChips
                  items={items}
                  componentEventId={componentEventId}
                  truncateLongText
                  trackEvent={trackEvent}
                />
              </div>
            ) : null
          )}
        </div>
      </div>

      <ExposureTracker
        onExposure={() => {
          if (!componentEventId) return

          trackEvent?.({
            attributeId: componentEventId,
            attributeType: EVENTS.ATTRIBUTES_TYPE.CHIPS,
            eventType: EVENTS.TYPE.EXPOSURE,
            attributeValue: {
              componentId,
              items: sections.map(({ items }: { items: [Item] }) =>
                items.map(({ id }, index) => {
                  return { id, index }
                })
              ),
            },
          })
        }}
      />
    </Container>
  )
}

export { WhatIsPopular }

import React from 'react'
import cn from 'classnames'

import s from './styles.module.scss'

interface ClipboardProps {
  copiedText?: string
  children: React.ReactNode
}

function useClipboard() {
  const [isCopied, setIsCopied] = React.useState(false)
  const [tooltipVisible, setTooltipVisible] = React.useState(false)

  const copyToClipboard = (item: string) => {
    navigator.clipboard.writeText(item)
    setIsCopied(true)
    requestAnimationFrame(() => setTooltipVisible(true))
  }

  React.useEffect(() => {
    if (tooltipVisible) {
      const id = setTimeout(() => {
        setIsCopied(false)
        setTooltipVisible(false)
      }, 2000)
      return () => clearTimeout(id)
    }
  }, [tooltipVisible])

  const Clipboard = ({ copiedText, children }: ClipboardProps) => (
    <section className={cn(s.clipboard, { [s.copied]: tooltipVisible })} data-copied-text={copiedText}>
      {children}
    </section>
  )

  return { isCopied, copyToClipboard, Clipboard }
}

export { useClipboard }

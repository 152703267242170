import { cloneDeep, get, has } from 'lodash-es'
import { exclude } from 'query-string'

import { ComponentModifiersObject } from 'lib/@Types'

import { ClientMapping } from './types'

// placeholders will be passed as {modifiers.day.selected.id} or {client-mapping.productIds}
const REGEX = /\{{(modifiers\.|client-mapping\.)(.+?)\}}/g

const getReplacedValue = (
  value: string,
  { modifiers, clientMapping }: { modifiers?: ComponentModifiersObject; clientMapping: ClientMapping }
) => {
  // matches example: ['{{modifiers.day.selected.id}}', '{{client-mapping.productIds}}']
  const matches = value.match(REGEX)
  // a value can have multiple matches, replace all matches with actual values
  return matches?.reduce((acc, placeholder) => {
    const placeholderWithoutBraces = placeholder.replace(/{{|}}/g, '')

    const dataPool = { modifiers, 'client-mapping': clientMapping }
    const hasProperty = has(dataPool, placeholderWithoutBraces)

    if (matches.length > 2 && !hasProperty) return undefined

    if (!hasProperty) return undefined

    const currentValue = get(dataPool, placeholderWithoutBraces)

    if (value === placeholder && typeof currentValue !== 'string') return currentValue

    // if found multiple placeholders and one of them is undefined, replace the entire text with undefined
    if (matches.length > 2 && !currentValue) return undefined

    return acc.replace(placeholder, currentValue)
  }, value)
}

const updatePlaceholders = (
  data: Record<string, any>,
  { modifiers, clientMapping }: { modifiers?: ComponentModifiersObject; clientMapping: ClientMapping }
) => {
  // recursive function to update placeholders in config or content
  const update = (obj: any) => {
    for (const key in obj) {
      const value = obj[key]
      if (value && typeof value === 'object') {
        update(value)
      } else {
        const isValueAsPlaceholder = typeof value === 'string' && REGEX.test(value?.trim())

        if (isValueAsPlaceholder) {
          const replacedValue = getReplacedValue(value, { modifiers, clientMapping })

          // if url has placeholder, and we are unable to replace it, instead of setting it undefined, we will exclude the modifiers from url
          // url example: search/?destinationIds=singapore&{{modifiers.tags.current.type}}={{modifiers.tags.current.id}}
          // will become search/?destinationIds=singapore
          if (key === 'url' && !replacedValue) {
            obj[key] = exclude(value, (k, v) => REGEX.test(k?.trim?.()) || REGEX.test(v.toString()?.trim?.()))
          } else {
            // replace placeholder with actual value
            // value example: singapore-things-to-do-d48/{{modifiers.day.current.id}}-dt{{modifiers.day.current.code}}
            obj[key] = replacedValue
          }
        }
      }

      const isKeyAsPlaceholder = REGEX.test(key?.trim())
      if (isKeyAsPlaceholder) {
        // replace placeholder with actual key
        // value example: {{modifiers.tags.current.type}}: {{modifiers.tags.current.id}}
        // here placeholder key is: {{modifiers.tags.current.type}}
        const newKey = getReplacedValue(key, { modifiers, clientMapping })
        if (newKey) {
          obj[newKey] = obj[key]
          delete obj[key]
        }
      }
    }
  }
  const clonedData = cloneDeep(data)
  update(clonedData)

  return clonedData
}

const isSkipComponentRender = (dependencies: string[] | undefined, componentConfig: Record<string, any>) => {
  if (!dependencies || !dependencies?.length) return false

  return dependencies?.some((keyToLookFor: string) => {
    const value: any = get(componentConfig, keyToLookFor)
    return (Array.isArray(value) && value.length === 0) || !value
  })
}

export { updatePlaceholders, isSkipComponentRender }

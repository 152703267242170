import { useEffect } from 'react'
import { Trans } from 'next-i18next'
import cn from 'classnames'

import { Button } from 'ui/button'
import { DrawerDialog } from 'ui/drawer-dialog'

import { ExternalLink } from 'components/external-link'

import useTranslation from 'lib/hooks/useTranslation'

import { useGlobalContext } from 'lib/context/global-context'

import { EVENTS } from 'lib/constants/events'
import {
  BEST_PRICE_GUARANTEED_HELP_TICKET_LINK,
  BEST_PRICE_GUARANTEED_PROMOTION_LINK,
  DISNEY_CRUISE_POLICY_LINK,
} from 'lib/constants'

import s from './styles.module.scss'

const HowItWorksModal = ({
  open,
  variant = 'best-price-guaranteed',
  onClose,
  metaData,
  trackEvent,
}: {
  open: boolean
  variant?: 'best-price-guaranteed' | 'partial-payment'
  metaData?: Record<string, any>
  onClose: () => void
  trackEvent: TrackEventType
}) => {
  const { isMobileView } = useGlobalContext()
  const { t, tp } = useTranslation('common')

  const MODAL_CONTENT = {
    'best-price-guaranteed': {
      title: t('action.bestPriceGuaranteed'),
      subTitle: tp('t.wePromiseRefundTheDifferenceViaPromoCode'),
      steps: [
        {
          title: t('t.gatherYourInformation'),
          subSteps: [tp('msg.youFoundALowerPriceOnOtherTravelExperiencesWebsite')],
        },
        {
          title: t('t.sendItToUs'),
          subSteps: (
            <>
              <Trans
                ns="common"
                i18nKey="msg.fillUpFormWithScreenshotAndWebsiteUrl"
                components={{
                  form_link: (
                    <ExternalLink
                      className={cn('tertiary-link', s.regularText)}
                      href={BEST_PRICE_GUARANTEED_HELP_TICKET_LINK}
                    />
                  ),
                }}
              />
              {t('lang.punctuation')}
            </>
          ),
        },
        {
          title: t('t.weWillReview'),
          subSteps: [tp('msg.ourCustomerCareTealWillReviewAppication')],
        },
        {
          title: t('t.receiveYourFund'),
          subSteps: [tp('msg.ifEverythingChecksOutWeWillRefundTheDifferenceViaPromoCode')],
        },
      ],
      note: tp('msg.pleaseNoteAllClaimAreSubjectToProductExclusions'),
      termsAndconditions: {
        link: BEST_PRICE_GUARANTEED_PROMOTION_LINK,
        cta: t('action.readOurTermsAndConditions'),
      },
    },
    'partial-payment': {
      title: t('t.bookNowPayOnlyPartToday', { deposit: metaData?.depositLabel }),
      subTitle: t('t.howItWorks'),
      steps: [
        {
          title: t('msg.payPartNow', { deposit: metaData?.depositLabel }),
          subSteps: [
            tp('msg.confirmYourBookingByPayingOnlyPartOfTotalAmountOnlyCreditCard', {
              ns: 'common',
              deposit: metaData?.depositLabel,
            }),
          ],
        },
        {
          title: t('msg.payTheRemainingAmountLater'),
          subSteps: tp('msg.yourCardWillBeSecurelySavedAndPaymentWillBeChargedAuto', {
            finalPaymentDueDate: metaData?.finalPaymentDueDays || '100',
          }),
        },
      ],
      note: '',
      termsAndconditions: {
        link: DISNEY_CRUISE_POLICY_LINK,
        cta: t('action.readOurTermsAndConditions'),
      },
    },
  }

  const currentModalContent = MODAL_CONTENT[variant]

  useEffect(() => {
    open &&
      trackEvent({
        attributeId:
          variant === 'best-price-guaranteed' ? EVENTS.BEST_PRICE_GUARANTEED : EVENTS.PARTIAL_PAYMENT,
        attributeType: EVENTS.ATTRIBUTES_TYPE.POPUP,
        eventType: EVENTS.TYPE.EXPOSURE,
      })
  }, [open, variant, trackEvent])

  return (
    <DrawerDialog
      header={t(currentModalContent?.title)}
      {...(!isMobileView
        ? { variant: 'medium', autoMinHeight: true }
        : { animation: 'slideUp', variant: 'auto', asBottomSheet: true })}
      open={open}
      onClose={onClose}
      onClickOutside={onClose}
      onEscKeyPress={onClose}
      bringToFront
    >
      <div className={cn({ ['mb-4']: isMobileView && variant === 'partial-payment' })}>
        <div className={s.title}>{currentModalContent?.subTitle}</div>
        <ol className={s.ol}>
          {currentModalContent.steps.map((step, index) => (
            <li key={index}>
              {step.title}
              <ol>
                {Array.isArray(step.subSteps)
                  ? step.subSteps.map((subStep, index) => <li key={index}>{subStep}</li>)
                  : step.subSteps}
              </ol>
            </li>
          ))}
        </ol>

        {currentModalContent?.note && <p>*{currentModalContent.note}</p>}

        <Button className="mt-2" variant="link-tertiary" size="small">
          <ExternalLink href={currentModalContent.termsAndconditions.link}>
            {currentModalContent.termsAndconditions.cta}
          </ExternalLink>
        </Button>
      </div>
    </DrawerDialog>
  )
}

export { HowItWorksModal }

import { RefObject, useState } from 'react'

import useLayoutEffect from 'lib/hooks/useLayoutEffect'

const useIsContentExpandable = (el: RefObject<HTMLElement>) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [isExpandable, setIsExpandable] = useState(false)

  useLayoutEffect(() => {
    if (el.current instanceof HTMLElement) {
      const { scrollHeight, clientHeight } = el.current
      setIsExpandable(clientHeight < scrollHeight)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [el, el.current])

  return { isExpandable, isExpanded, setIsExpanded }
}

export default useIsContentExpandable

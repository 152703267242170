import cn from 'classnames'

import { Image } from 'ui/image'
import { Button } from 'ui/button'

import { ExternalLink } from 'components/external-link'
import { ExposureTracker } from 'components/exposure-tracker'
import { DynamicComponentProps } from 'components/dynamic-components/types'

import useTranslation from 'lib/hooks/useTranslation'

import { useGlobalContext } from 'lib/context/global-context'

import { APP_STORE_LINK, GOOGLE_PLAYSTORE_LINK, MOBILE_APP_DOWNLOAD_LINK } from 'lib/constants'
import { EVENTS } from 'lib/constants/events'

import Doodle from 'brand-assets/illustrations/itinerary-doodle.svg'
import DownloadAppStore from 'brand-assets/illustrations/download-app-store.svg'
import DownloadGooglePlaystore from 'brand-assets/illustrations/download-google-playstore.svg'
import MobileAppQrCode from 'brand-assets/illustrations/mobile-app-qrcode.svg'

import s from './styles.module.scss'

const bigAppMockupUri = '/app-mockup-desktop.png'
const appMockupUri = '/app-mockup.png'

type MobileAppBannerProps = DynamicComponentProps & {
  variant?: 'compact' | 'normal'
  noPageSpacePadding?: boolean
  trackEvent: TrackEventType
}

const MobileAppBanner = ({
  variant = 'compact',
  trackEvent,
  componentEventId,
  componentContent,
  noPageSpacePadding,
}: MobileAppBannerProps) => {
  const { variant: variantFromComponentContent } = componentContent || {}
  const isCompact = variantFromComponentContent
    ? variantFromComponentContent === 'compact'
    : variant === 'compact'
  const {
    isTabletView,
    globalArgs: { appTouchpointsData },
  } = useGlobalContext()
  const { header, subHeader } = appTouchpointsData
  const { t } = useTranslation('common')

  const handleClickDownload = (id?: string) => () => {
    trackEvent({
      attributeId: componentEventId,
      attributeType: EVENTS.ATTRIBUTES_TYPE.BANNER,
      eventType: EVENTS.TYPE.CLICK,
      attributeValue: id
        ? {
            id,
          }
        : undefined,
    })
  }

  return (
    <div className={cn(s.appBannerContainer, { [s.noPadding]: noPageSpacePadding })}>
      <div className={cn(s.bannerContainer, { [s.compact]: isCompact })}>
        <div className={cn(s.mobileAppBanner, { [s.compact]: isCompact })}>
          {!isCompact && (
            <div className={s.illustration}>
              <Doodle />
            </div>
          )}
          <div className={s.content}>
            {isCompact && (
              <div className={s.illustration}>
                <Doodle />
              </div>
            )}
            {isCompact && (
              <div className={s.appMockupContainer}>
                <div className={s.appMockup}>
                  <Image src={{ url: appMockupUri }} alt="pelago mobile app" layout="fill" />
                </div>
              </div>
            )}
            <div className={s.innerContent}>
              <h4 className={s.title} dangerouslySetInnerHTML={{ __html: header }} />
              {isCompact && !isTabletView && <p className={s.description}>{subHeader}</p>}
            </div>
            {isCompact && !isTabletView ? (
              <div className={s.qrCodeSection}>
                <MobileAppQrCode />
                <span>{t('t.scanToDownload')}</span>
              </div>
            ) : (
              <div className={s.footer}>
                <div className={s.footerContent}>
                  <p>{subHeader}</p>
                  <div className={s.buttonContainer}>
                    {isTabletView ? (
                      <ExternalLink href={MOBILE_APP_DOWNLOAD_LINK}>
                        <Button onClick={handleClickDownload()} variant="primary-neon" size="medium">
                          {t('action.downloadNow')}
                        </Button>
                      </ExternalLink>
                    ) : (
                      <>
                        <ExternalLink onClick={handleClickDownload('app-store')} href={APP_STORE_LINK}>
                          <DownloadAppStore />
                        </ExternalLink>
                        <ExternalLink
                          onClick={handleClickDownload('play-store')}
                          href={GOOGLE_PLAYSTORE_LINK}
                        >
                          <DownloadGooglePlaystore />
                        </ExternalLink>
                      </>
                    )}
                  </div>
                </div>
                <div className={s.qrCodeSection}>
                  <MobileAppQrCode />
                  <span>{t('t.scanToDownload')}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        {!isCompact && (
          <div className={s.normalAppMockup}>
            <Image
              src={{
                url: bigAppMockupUri,
                sizes: { medium: appMockupUri, large: bigAppMockupUri },
              }}
              alt="pelago mobile app"
              layout="fill"
            />
          </div>
        )}
      </div>
      <ExposureTracker
        onExposure={() => {
          trackEvent({
            attributeId: componentEventId,
            attributeType: EVENTS.ATTRIBUTES_TYPE.BANNER,
            eventType: EVENTS.TYPE.EXPOSURE,
          })
        }}
      />
    </div>
  )
}

export { MobileAppBanner }

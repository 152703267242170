import React, { useEffect, useMemo, useRef } from 'react'
import formatDistance from 'date-fns/formatDistance'
import cn from 'classnames'

import { ProductDetailsLink } from 'components/product-details-link'
import { Rating } from 'components/rating'

import { ReadMoreCta } from 'page-modules/product/read-more-cta'
import useIsContentExpandable from 'page-modules/product/useIsContentExpandable'

import useCustomTranslation from 'lib/hooks/useTranslation'

import { dateStringToLocalDate, removeHtmlTag } from 'lib/utils'

import PelagoBlackIcon from 'brand-assets/illustrations/logo/pelago-black.svg'
import ViatorLogoIcon from 'brand-assets/illustrations/logo/viator-logo.svg'

import { ReviewCard } from './review-card'

import s from './styles.module.scss'

type ReviewProps = {
  item?: Review
  dateLocale?: Locale
  variant?: 'pagination' | 'home'
  bringIntoView?: boolean
  showCompanyLogo?: boolean
  onExpandClick?: () => void
  onProductNameClick?: (args0: Record<string, any>) => void
}

const LOGOS: Record<ReviewProvider, any> = {
  PELAGO: PelagoBlackIcon,
  VIATOR: ViatorLogoIcon,
  BMG: null,
  GLOBALTIX: null,
  KKDAY: null,
  REZDY: null,
  TIQETS: null,
}

const ReviewItem = ({
  item,
  dateLocale,
  variant,
  bringIntoView,
  showCompanyLogo,
  onExpandClick,
  onProductNameClick,
}: ReviewProps) => {
  const contentRef = useRef<HTMLParagraphElement>(null)
  const { t } = useCustomTranslation(['product'])
  const { isExpandable, isExpanded, setIsExpanded } = useIsContentExpandable(contentRef)
  const {
    travellerType,
    reviewDate,
    rating,
    firstName,
    comment,
    title,
    productName,
    productId,
    productUri,
    externalSource,
  } = item || {}
  const showReadMoreCta = ['pagination', 'home'].indexOf(variant || '') === -1 && isExpandable
  const isThirdPartyReviews = externalSource !== null
  const CompanyLogo = externalSource ? LOGOS[externalSource] : LOGOS.PELAGO

  const isModalPaginationReview = variant === 'pagination'

  useEffect(() => {
    if (!bringIntoView) return
    setTimeout(() => {
      if (contentRef?.current) {
        contentRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }, 300)
  }, [bringIntoView])

  const commentDate = useMemo(() => {
    const today = new Date()
    if (!reviewDate || !dateLocale?.code) return null

    return formatDistance(dateStringToLocalDate(reviewDate), today, {
      addSuffix: true,
      locale: dateLocale,
    })
  }, [dateLocale, reviewDate])

  const displayComment = useMemo(() => {
    return removeHtmlTag(comment)
  }, [comment])

  if (variant === 'home') {
    return (
      <ReviewCard
        title={title}
        displayComment={displayComment}
        firstName={firstName}
        rating={rating}
        showReadMoreCta={showReadMoreCta}
        productId={productId}
        productName={productName}
        productUri={productUri}
      />
    )
  }

  return (
    <div className={cn(s.box, { [s.pagination]: isModalPaginationReview })}>
      <div className={s.background}>
        <div className={s.body}>
          <div className={s.reviewHeader}>
            <Rating value={rating || 0} isReadOnly />
            {!isModalPaginationReview && <span className={s.date}>{commentDate}</span>}
          </div>
          {!isModalPaginationReview && (
            <div className={s.nameAndType}>
              <span>{firstName}</span>
              {travellerType && (
                <>
                  <span className={s.separator}>.</span>
                  <span className={s.travellerType}>{travellerType}</span>
                </>
              )}
            </div>
          )}
          <p
            className={cn(s.comment, {
              [s.expanded]: isExpanded || isModalPaginationReview,
              [s.expandable]: isExpandable,
              [s.ellipsize]: !isModalPaginationReview,
            })}
            ref={contentRef}
          >
            {title && (
              <>
                {title}
                <span className={s.separator} />
              </>
            )}
            {displayComment}
          </p>
          {showReadMoreCta && (
            <ReadMoreCta
              isExpanded={isExpanded}
              normalWeight
              onClick={() => {
                if (onExpandClick) onExpandClick?.()
                else setIsExpanded(!isExpanded)
              }}
              size="small"
            />
          )}
        </div>
        <div className={s.footer}>
          {isModalPaginationReview && (
            <div className={s.nameAndType}>
              <span>{firstName}</span>
              {travellerType && (
                <>
                  <span className={s.separator}>.</span>
                  <span>{travellerType}</span>
                </>
              )}
            </div>
          )}
          <div className={s.flex}>
            {isModalPaginationReview && <div className={s.dateOrProductName}>{commentDate}</div>}
            <ProductDetailsLink
              productId={productId}
              productUri={productUri}
              onClick={() => {
                onProductNameClick?.({ id: productId })
              }}
            >
              {productName}
            </ProductDetailsLink>
            {showCompanyLogo && (
              <div className={s.logoContainer}>
                {isThirdPartyReviews && !!CompanyLogo && (
                  <span className="text-xs">{t('collectedBy', { ns: 'product' })}</span>
                )}
                <CompanyLogo />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export { ReviewItem }
